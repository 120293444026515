.chat-box {
    height: 80vh; /* Set the desired height for your chat box */
    overflow-y: auto; /* Enable vertical scrolling */

    /* Hide the scrollbar */
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

.chat-box::-webkit-scrollbar {
    display: none; /* Hide the scrollbar for Chrome, Safari, and Opera */
}
.message-time {
    font-size: x-small;
}

input.form-control:focus {
    outline: none;
    box-shadow: none;
}

.bg-receiver {
    background-color: #0d6efd45;
}

.custom-icon {
    font-size: 4rem; /* Adjust the size as needed */
}

/* .p-button-label{
    display: none
  } */

/* Custom Css */

.field_label {
    display: block !important;
}

.steric {
    color: red;
}
.disable-icon-color {
    color: rgb(175, 59, 59);
}
.icon-size {
    font-size: 14px;
}
.disable-color {
    color: rgb(13, 5, 5);
    font-size: 16px;
}
.yesButton {
    background-color: green;
}
.noButton {
    background-color: green;
}

.sp_small small {
    display: block;
    padding-top: 5px;
}
.acp_programs img {
    width: 100%;
}
.plans_table tr th {
    padding-right: 20px;
    padding-bottom: 10px;
}
.plans_table tr td {
    padding-bottom: 10px;
}
.w_100 {
    width: 100%;
}

/* Enrollment Pricing Cards */

/* .wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -190px 0 0 -468px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    color: #9f9f9f;
    font-size: 15px;
} */
.package {
    box-sizing: border-box;
    /* width: 301px; */
    /* height: 380px; */
    border: 3px solid #e8e8e8;
    border-radius: 7px;
    display: inline-block;
    padding: 24px;
    text-align: center;
    float: left;
    transition: margin-top 0.5s linear;
    position: relative;
    margin-right: 11px;
}
.package:hover {
    margin-top: -10px;
    transition: margin-top 0.3s linear;
}
.package .name {
    color: #565656;
    font-weight: 300;
    font-size: 3rem;
    margin-top: -5px;
}
.package .price {
    margin-top: 7px;
    font-weight: bold;
}
/* .package .price::after {
    content: " / month per user";
    font-weight: normal;
} */
.package hr {
    background-color: #dedede;
    border: none;
    height: 1px;
}
.package .trial {
    font-size: 0.9rem;
    font-weight: 600;
    padding: 2px 21px 2px 21px;
    color: #33c4b6;
    border: 1px solid #e4e4e4;
    display: inline-block;
    border-radius: 15px;
    background-color: white;
    position: relative;
    bottom: -25px;
}
.package ul {
    list-style: none;
    padding: 0;
    text-align: left;
    margin-top: 29px;
}
.package li {
    margin-bottom: 15px;
}
.package li:before {
    font-size: 1.3rem;
    color: #33c4b6;
    margin-right: 3px;
}

.brilliant {
    border-color: #33c4b6;
}
.brilliant::before {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 64px 64px 0 0;
    border-color: #3bc6b8 transparent transparent transparent;
    position: absolute;
    left: 0;
    top: 0;
    content: "";
}
.brilliant::after {
    color: white;
    position: absolute;
    left: 9px;
    top: 6px;
    text-shadow: 0 0 2px #37c5b6;
    font-size: 1.4rem;
}
.cursor-crosshair:hover {
    cursor: not-allowed;
}

/* Style for answer buttons (Yes) */
.answer-button.green {
    background-color: green;
    color: white;
    padding: 12px 22px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

/* Style for answer buttons (No) */
.answer-button.red {
    background-color: rgba(255, 0, 0, 0.789);
    color: white;
    padding: 12px 22px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

/* Style for answer button text */
.answer-button span {
    font-weight: bold;
}
.button-design {
    margin-right: 10px;
    margin-top: 10px;
    font-size: bold;
    padding: 12px 26px;
    border-radius: 6px;
    color: white;
    background-color: rgba(0, 0, 0, 0.661);
    border: none;
}
input:disabled {
    cursor: not-allowed;
}
.final-pre {
    justify-content: center;
    width: 900px;
    align-items: center;
    margin-left: 170px;
    margin-top: 60px;
    box-shadow: 2px;
}
.final-btn {
    margin-top: 20px;
    margin-left: 780px;
}
.sticky-buttons {
    position: sticky;
    top: 65px;
    background-color: white;
    z-index: 1;
    padding: 10px;
}

/* Pagination container */
.pagination {
    display: flex;
    justify-content: center;
    list-style: none;
    padding: 0;
    margin: 20px 0;
}

/* Pagination item (buttons) */
.pagination li {
    display: inline-block;
    margin-right: 10px;
    margin-top: 10px;
    margin-bottom: 20px;
    cursor: pointer;
}

/* Active page button */
.pagination .active {
    font-weight: bold;
    font-size: 16px;
}

/* Previous and Next buttons */
.pagination .previous,
.pagination .next {
    font-weight: bold;
    cursor: pointer;
}

/* TemplateSearchBar.css  */

.search-bar {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.search-input {
    flex-grow: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 16px;
}

.search-button {
    margin-left: 10px;
    cursor: pointer;
    color: #ffffff;
    background: #6366f1;
    border: 1px solid #6366f1;
    padding: 0.75rem 1.25rem;
    font-size: 1rem;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 6px;
}

.search-button:hover {
    background-color: #0056b3;
}

.bar-place {
    justify-content: space-between;
}
.erormsg {
    margin-left: 900px;
    color: red;
    font-size: 20px;
}
.no-border {
    border: none;
}

.hide-reject-button .p-confirm-dialog-footer .p-button-reject {
    display: none;
}

.centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.Label__Text {
    font-size: 14px;
    font-weight: 500;
    color: #000000;
    /* font-family: sans-serif; */
    font-style: font-italic;
    padding-left: 5px;
    float: none;
}

.Input__Round {
    display: block;
    width: 100%;
    padding: 0.375rem 1rem !important;
    font-size: 0.81rem;
    margin-right: 13px;
    /* margin-bottom: 8px; */
    line-height: 2;
    color: #484f56;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-radius: 10px;
    border: 1px solid #dbe2ea;
    box-shadow: rgba(181, 181, 181, 0.25) 0px 12px 12px -12px inset, rgba(255, 255, 255, 0.733) 0px 18px 36px -18px inset;
}
.checkBox {
    display: block;
    width: 100%;
    padding: 0.375rem 1rem !important;
    font-size: 0.81rem;
    margin-right: 13px;
    /* margin-bottom: 8px; */
    line-height: 2;
    color: #484f56;
    background-color: #fff;
    background-clip: padding-box;
}
.Date__Round {
    display: block;
    width: 15rem;
    padding: 0.375rem 1rem !important;
    font-size: 0.81rem;
    margin-right: 13px;
    /* margin-bottom: 8px; */
    line-height: 2;
    color: #484f56;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-radius: 10px;
    border: 1px solid #dbe2ea;
    box-shadow: rgba(181, 181, 181, 0.25) 0px 12px 12px -12px inset, rgba(255, 255, 255, 0.733) 0px 18px 36px -18px inset;
}

.Dropdown__Round {
    width: 100%;
    font-size: 0.81rem;
    line-height: 1;
    color: #484f56;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-radius: 10px;
    /* font-family: $titleTextFont; */
    /* margin-top: 7px; */
    /* padding: 0.375rem 0.4rem !important; */
    /* border: 1px solid $inputBorder; */
    border: 1px solid #dbe2ea;

    box-shadow: rgba(181, 181, 181, 0.25) 0px 12px 12px -12px inset, rgba(255, 255, 255, 0.733) 0px 18px 36px -18px inset;

    .p-placeholder {
        font-size: 0.84rem;
        /* padding: 0.375rem 1rem !important; */
    }
}

.Btn__Dark {
    font-size: 13px;
    height: 40px;
    font-weight: 300;
    border-radius: 10px;
    cursor: pointer;
    width: 150px;
    margin-left: 5px;
    border-color: #509793 !important;
    background-color: #509793 !important; /* Setting button color with !important */
}

/* Ensuring hover, active, and focus states don't change the button color */
.Btn__Dark:hover,
.Btn__Dark:active,
.Btn__Dark:focus {
    background-color: #509793 !important;
}

.Btn__Dark .p-button-label {
    font-weight: 500;
    white-space: nowrap;
}

.Page__Header {
    font-weight: 500;
    font-size: 20px;
    border-bottom: 1px solid #d2d2d2;
    color: #6d9d93 !important;
    /* font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif; */
}

.Manage__Header {
    font-weight: 600;
    font-size: 20px;
    color: #6d9d93 !important;

    /* font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif; */
}
.actions {
    display: flex;
    /* // justify-content: space-evenly; */
    justify-content: center;
    align-items: center;
    &.actions > * {
        margin: 0 0.25rem;
    }
}

.centered__login {
    text-align: center;
    background: #366c77 !important;
    width: 450px !important;
    margin: 0 auto !important;
    padding: 0px 60px !important;
    border-radius: 20px;
}

/* LoginScreen.css */

.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}

.login-card {
    width: 350px;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #fff;
}

.login-header {
    text-align: center;
}

.login-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
}

.login-error {
    text-align: center;
    margin-bottom: 10px;
    color: red;
}

.login-form {
    display: flex;
    flex-direction: column;
}

.p-inputtext-sm {
    width: 100%;
    margin-bottom: 10px;
}

.error-text {
    color: red;
    margin-top: 0;
    font-size: 12px;
}

.login-button {
    width: 100%;
    margin-top: 10px;
    background-color: #509793 !important;
    border-color: #509793 !important;
}

.forgot-password {
    text-align: center;
    margin-top: 10px;
}

.forgot-password-link {
    font-weight: bold;
    color: #509793 !important;
    text-decoration: none;
}

.chat-card {
    width: 70%;
    margin: auto;
    margin-top: 50px;
}

.chat-messages {
    display: flex;
    flex-direction: column;
}

.message {
    border: 1px solid #ccc;
    border-radius: 10px;

    padding: 10px;
    margin: 5px;
    word-wrap: break-word; /* Allow text to wrap onto the next line */
}

.message.user {
    align-self: flex-end;
    background-color: cadetblue;
}

.message.other {
    align-self: flex-start;
    background-color: rgb(212, 212, 212);
}

.input-container {
    display: flex;
}

.full-width-input {
    width: 100%;
}

@media print {
    #qr {
        /* Define styles for the QR div when printing */
        /* Ensure visibility and proper layout for print */
    }
}
